import { tosh } from '@theme-ui/presets'

// Config file https://github.com/system-ui/theme-ui/blob/master/packages/preset-tosh/src/index.ts

export default {
  ...tosh,
  colors: {
    ...tosh.colors,
    sidebarBackground: '#202020',
    sidebarText: 'rgba(255,255,255,.5)',
  },
  styles: {
    ...tosh.styles,
  },
  text: {
    sidebarTitle: {
      color: 'modes.dark.text',
    },
  },
}
